@import '../_common/c-header';

.c-header {
    background-color: $body-bg;
    color: $body-color;
}
.c-header__title {
    font-size: 1.375rem;
    line-height: 1.45;
    color: $primary;
    font-weight: $font-weight-bold;
}
.c-header__back {
    &:hover,
    &:focus {
        text-decoration: underline;
    }
    .c-back__ico {
        color: $secondary;
    }
}