@import '../_common/c-swatch';

.c-swatch {
    padding: 3px;
    &.is-active {
        padding: 4px;
        box-shadow: 0 0 0 0.05rem $black;
    }
}
.c-swatch__ico {
    padding: 0.1em;
    background-color: $secondary;
    color: $white;
}