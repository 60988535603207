@import '../_common/c-bottombar';

.c-bottombar__head {
    background-color: $primary;
    color: $white;
}
//.c-bottombar__info {
//    .c-info__title {
//        font-size: .75rem;
//        font-weight: $font-weight-normal;
//    }
//    .c-info__price {
//        font-size: 1rem;
//        font-weight: $font-weight-bold;
//    }
//}
.c-bottombar__btnline {
    .btn {
        &:hover,
        &:focus {
            .btn-ico {
                background-color: $black;
                color: $white;
            }
        }
    }
    .btn-ico {
        border-radius: 50%;
        background-color: $white;
        color: $primary;
        font-size: 1.75rem;
    }
}
.c-bottombar__toggle {
    &:hover,
    &:focus,
    .is-expanded & {
        color: inherit;
    }
}