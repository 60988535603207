@import '../_common/nav';

.nav-tabs {
    font-family: $font-family-brand;
    .nav-link {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-left: 0;
        padding-right: 0;
        color: inherit;
        font-weight: $font-weight-bold;
    }
}