@import '../_common/c-grade';

.c-grades {
    background-color: $gray-100;
}
.c-grade__title {
    font-size: 1.375rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}
.c-grade__price {
    margin: 1.25rem 0 0;
    .c-price {
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
    }
}
.c-grade__tech {
    font-size: .875rem;
    font-weight: $font-weight-bold;
}