@import '../_common/c-steps';

.c-steps {
    background-color: $body-bg;
    color: $body-color;
    @include media-breakpoint-down(lg) {
        background-color: $primary;
    }
}
.c-steps__nav {
    background-color: $primary;
    color: $white;
    font-size: .875rem;
    @include media-breakpoint-up(lg) {
        border: 1px solid $gray-400;
        border-width: 1px 0;
        background-color: $body-bg;
        color: $gray-600;
        font-size: .75rem;
    }
}
.c-steps__link {
    height: 2.5rem;
    border-top: 1px solid #d6d2c4;
    text-align: left;
    &:first-child {
        border-top: 0;
    }
    &.active {
        font-weight: $font-weight-bold;
    }
    @include media-breakpoint-up(lg) {
        flex: 1;
        padding: 0 20px 0 5px;
        border-top: 0;
        &::before,
        &::after {
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            width: 0;
            height: 0;
            margin-top: -50px;
            margin-left: -21px;
            border: 1px solid transparent;
            border-width: 50px 0 50px 20px;
            content: "";
        }
        &::before {
            z-index: 1;
            margin-left: -20px;
            border-left-color: $gray-400;
        }
        &::after {
            z-index: 2;
            border-left-color: $body-bg;
        }
        &:hover,
        &:focus {
            color: $black;
            font-weight: $font-weight-bold;
        }
        &.active {
            color: $body-color;
        }
    }
}

.c-steps__text {
    @include media-breakpoint-up(lg) {
        margin: 0 30px;
        border: 3px solid transparent;
        .active > & {
            border-bottom-color: $primary;
        }
    }
}