@font-face {
  font-family: "Read";
  src: url('../../fonts/theme-dacia/Read-Light_V3000.woff2') format("woff2"), url('../../fonts/theme-dacia/Read-Light_V3000.woff') format("woff");
  font-weight: 300;
  font-display: swap;
  //unicode-range: u+000-20cf
}
@font-face {
  font-family: "Read";
  src: url('../../fonts/theme-dacia/Read-Regular_V3000.woff2') format("woff2"), url('../../fonts/theme-dacia/Read-Regular_V3000.woff') format("woff");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Read";
  src: url('../../fonts/theme-dacia/Read-Medium_V3000.woff2') format("woff2"), url('../../fonts/theme-dacia/Read-Medium_V3000.woff') format("woff");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Read";
  src: url('../../fonts/theme-dacia/Read-Bold_V3000.woff2') format("woff2"), url('../../fonts/theme-dacia/Read-Bold_V3000.woff') format("woff");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Dacia";
  src: url('../../fonts/theme-dacia/DaciaBlock-Light.woff2') format("woff2"), url('../../fonts/theme-dacia/DaciaBlock-Light.woff') format("woff");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Dacia";
  src: url('../../fonts/theme-dacia/DaciaBlock-Regular.woff2') format("woff2"), url('../../fonts/theme-dacia/DaciaBlock-Regular.woff') format("woff");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Dacia";
  src: url('../../fonts/theme-dacia/DaciaBlock-Bold.woff2') format("woff2"), url('../../fonts/theme-dacia/DaciaBlock-Bold.woff') format("woff");
  font-weight: 700;
  font-display: swap;
}