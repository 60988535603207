@import '../_common/c-aside';

.c-aside-container {
    border-left: 1px solid $gray-400;
}
.c-aside {
    @include media-breakpoint-down(lg) {
        background-color: $primary;
        color: $white;
    }
}
.c-aside__title {
    font-size: .75rem;
    font-weight: $font-weight-normal;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;
        font-size: inherit;
    }
}
.c-aside__price {
    font-size: inherit;
    font-weight: $font-weight-bold;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
}
.c-aside__btnline {
    .btn-primary {
        @include media-breakpoint-down(lg) {
            border-color: transparent;
            background-color: $black;
        }
    }
}