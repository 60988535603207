@import '../_common/c-engine';

.c-engines {
    background-color: $gray-100;
}
.c-engine {
    padding: 0.5rem 1.5rem;
    color: $gray-900;
}
.c-engine__head {
    width: auto;
    margin: -0.5rem -1.5rem 1.5rem;
    padding: 1rem 1.5rem;
    background-color: $primary;
    color: $white;
}
.c-engine__title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: $font-weight-normal;
}
.c-engine__price {
    margin: 1rem 0;
    padding: 1rem 0;
    border: 1px solid $gray-400;
    border-width: 1px 0;
    .c-price {
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
    }
}
.c-engine__descr {
    color: $gray-700;
    font-size: .6875rem;
}
.c-engine__tech {
    order: -1;
    flex-direction: column;
    .c-tech {
        flex-direction: row;
        justify-content: initial;
        min-height: auto;
        padding: 0;
        background-color: transparent;
        color: $gray-900;
        text-align: inherit;
    }
    .c-tech__ico {    
        margin-right: 1rem;
    }
}
.c-engine__tech-item {
    padding: 0;
    & + & {
        margin-top: 1rem;
    }
}
.c-engine__btnline {
    margin: 0;
}
.c-engine__footer {
    margin: 1rem -1.5rem -0.5rem;
    padding: 1rem 1.5rem;
}
.c-engine__check {
    font-size: 0.875rem;
    .form-check-input {
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        font-size: 1rem;
    }
}