// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f2f2f2;
$gray-200: $gray-100;
$gray-300: $gray-100;
$gray-400: #ccc;
$gray-500: $gray-400;
$gray-600: #777;
$gray-700: $gray-600;
$gray-800: $gray-600;
$gray-900: #333;
$black:    #000;

// scss-docs-start theme-color-variables
$primary:       #646b52;
$primary-light: #f8eb4c;
$secondary:     #ec6528;
$success:       #289548;
//$info:          $cyan !default;
//$warning:       $yellow !default;
$danger:        #e91630;
//$light:         $gray-100 !default;
//$dark:          $gray-900 !default;

// Typography
// scss-docs-start font-variables
$font-family-base: "Read", sans-serif;
$font-family-brand: "Dacia", sans-serif;
//$font-size-base: .875rem;

$h1-font-size: 1.875rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.375rem;
$h4-font-size: 1.375rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.125rem;

//$small-font-size: .875rem;

// scss-docs-start headings-variables
$headings-font-family: $font-family-brand;
$headings-font-weight: 700;
$headings-letter-spacing: normal;

// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: false;
$enable-caret: false;
$enable-validation-icons: false;
$enable-shadows: false;

$component-active-color: $black;

// Settings for the `<body>` element.
$body-color: $black;
$body-font-size: .875rem;
$body-font-weight: 400;
$body-line-height: 1.5rem;
$body-letter-spacing: normal;

// scss-docs-start form-check-variables
$form-check-input-checked-color:          $primary;
$form-check-input-checked-bg-color:       $white;
$form-check-input-checked-border-color:   $primary;
$form-check-input-border-radius: 0;

// Links
// Style anchor elements.
$link-color:                              inherit;
$link-hover-color:                        inherit;

// Buttons + Forms
//$input-btn-font-size: 1rem;
$btn-box-shadow:              none;
$btn-font-weight:             700;
$btn-font-size:               $body-font-size;
$btn-link-color:              inherit;


// Navs
// scss-docs-start nav-variables
$nav-tabs-border-width:             0;
$nav-tabs-link-hover-border-color:  transparent;
$nav-tabs-link-active-color:        $black;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: $primary;

//$accordion-icon-color: $primary;
//$accordion-icon-active-color: $primary;
//$accordion-icon-width: 1rem;
//$accordion-button-active-bg: inherit;
//$accordion-button-active-color: inherit;
//$accordion-button-focus-border-color: inherit;
//$accordion-button-focus-box-shadow: none;
//$accordion-border-radius: 0;
//$accordion-button-padding-y: 0.5rem;
//$accordion-button-padding-x: 0;
//$accordion-body-padding-x: 0;

//$input-btn-padding-y: 0.625rem;
//$input-btn-padding-x: 1.875rem;
//$input-btn-line-height: 2.4rem;

//$btn-border-radius: $border-radius-pill;

//$btn-transition: background-color 0.3s ease 0s;

//$card-border-color: $gray-200;
//$card-border-radius: 0;

//$form-check-input-border-radius: 0.15em;

//$input-bg: rgba(0, 0, 0, 0.05);
//$input-hover-bg: $gray-300;
//$input-focus-bg: $gray-400;

//$input-border-color:
//$input-focus-border-color:

$input-focus-box-shadow: none;
//$input-height: 3.25rem;

//$list-group-item-padding-x: 0;

//$navbar-brand-font-size: $font-size-base;
//$nav-pills-link-active-color: $secondary;

//$popover-box-shadow: $box-shadow-lg;

//$enable-negative-margins: true;

//$box-shadow-up: rgb(0 0 0 / 10%) 0px -5px 20px;


// scss-docs-start list-group-variables
$list-group-item-padding-x: 0;

// scss-docs-start accordion-variables
$accordion-button-active-bg:              $white;
$accordion-button-active-color:           inherit;
$accordion-icon-width:                    1rem;
$accordion-icon-active-color:             $primary;
