@import '../_common/buttons';

.btn {
    border-radius: 0;
    text-transform: uppercase;
}

.btn-primary {
    &:hover,
    &:focus,
    &:active {
        border-color: $black;
        background-color: $black;
    }
}
.btn-secondary {
    border-color: $primary;
    background-color: $white;
    color: $black;
    &:hover,
    &:focus,
    &:active {
        border-color: $black;
        background-color: $black;
        color: $white;
    }
}
.btn-light {
    border-color: $gray-400;
    background-color: $white;
    color: $gray-600;
    &:hover,
    &:focus,
    &:active {
        border-color: $gray-900;
        background-color: $gray-900;
        color: $white;
    }
}
.btn-link {
    font-weight: $btn-font-weight;
    .btn-ico {
        color: $secondary;
    }
}