@import '../_common/swiper';

.card-swiper {
    .swiper-button {
        &:hover {
            background-color: $primary;
        }
    }
    .swiper-pagination-bullet-active {
        &::before {
            border-width: 1px;
            box-shadow: none;
        }
    }
}
.carpart-swiper {
    .swiper-slide {
        width: 290px;
    }
}
.swatch-swiper {
    .swiper-label {
        border-top-color: $gray-400;
    }
}